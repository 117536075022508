<!--suppress JSUnusedGlobalSymbols -->
<script>
import store from '@/infrastructure/store'
import { reactiveFormatRelativeTimeAgo } from '@/infrastructure/dates/dateFormatting'
import { toRefs } from '@vue/composition-api'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import ItemPriceAndContributionSummary from '@/features/items/components/ItemPriceAndContributionSummary'
import ItemInfoSection from '@/features/items/components/ItemInfoSection'
import ItemContributionsCard from '@/features/items/components/ItemContributionsCard'
import ItemCardRating from '@/features/items/components/ItemCardRating'
import MenuWithActions from '@/infrastructure/components/MenuWithActions'
import ItemNameField from '@/features/items/components/fields/ItemNameField'
import ItemPriceField from '@/features/items/components/fields/ItemPriceField'
import ItemShopField from '@/features/items/components/fields/ItemShopField'
import ItemImageField from '@/features/items/components/fields/ItemImageField'
import useApiImageUploading from '@/features/images/composables/imageFileComposables'

export default {
  components: {
    MenuWithActions,
    ItemCardRating,
    ItemPriceAndContributionSummary,
    ItemInfoSection,
    ItemContributionsCard,
    ItemNameField,
    ItemPriceField,
    ItemShopField,
    ItemImageField,
  },

  props: {
    authUserId: { type: String, required: true },
    itemId: { type: String, required: true },
    name: { type: String, required: true },
    description: { type: String },
    rating: { type: Number, default: () => 0 },
    price: { type: Number },
    shop: { type: String },
    links: { type: Array, default: () => [] },
    archived: { type: String },
    ownerId: { type: String, required: true },
    addedById: { type: String, required: true },
    addedByName: { type: String, required: true },
    claimedBy: { type: Object },
    contributions: { type: Object },
    imageIds: { type: Array },
    isHighlighted: { type: Boolean },
    highlightedItemScrollOffset: { type: Number },
  },

  setup(props) {
    const archivedRelativeTimeAgo = reactiveFormatRelativeTimeAgo(toRefs(props).archived)

    const claimItem = () => store.dispatch('items/claimFriendItem', { itemId: props.itemId, claimSecretly: false })
    const claimItemSecretly = () => store.dispatch('items/claimFriendItem', { itemId: props.itemId, claimSecretly: true })
    const unclaimItem = () => store.dispatch('items/unclaimFriendItem', { itemId: props.itemId })
    const contributeOnItem = (amount) =>
      store.dispatch('items/contributeOnFriendItem', {
        itemId: props.itemId,
        amount: amount,
      })

    const { isBusy: isArchiving, execute: executeArchive } = useAsyncLoading({
      actionFactory: () => store.dispatch('items/archiveFriendItem', { itemId: props.itemId }),
    })

    const { isBusy: isUnarchiving, execute: executeUnarchive } = useAsyncLoading({
      actionFactory: () => store.dispatch('items/unarchiveFriendItem', { itemId: props.itemId }),
    })

    const { isBusy: isBusyClaiming, execute: executeClaim } = useAsyncLoading({ actionFactory: claimItem })
    const { isBusy: isBusyClaimingSecretly, execute: executeClaimSecretly } = useAsyncLoading({ actionFactory: claimItemSecretly })
    const { isBusy: isBusyUnclaiming, execute: executeUnclaim } = useAsyncLoading({ actionFactory: unclaimItem })
    const { isBusy: isSavingContribution, execute: executeContribute } = useAsyncLoading({ actionFactory: contributeOnItem })

    const saveName = async ({ newName }) => {
      await store.dispatch('items/updateFriendItemName', { itemId: props.itemId, newName: newName })
    }

    const savePrice = async ({ newPrice }) => {
      await store.dispatch('items/updateFriendItemPrice', { itemId: props.itemId, newPrice: newPrice })
    }

    const saveRating = async ({ newRating }) => {
      await store.dispatch('items/updateFriendItemRating', { itemId: props.itemId, newRating: newRating })
    }

    const saveShop = async ({ newShop }) => {
      await store.dispatch('items/updateFriendItemShop', { itemId: props.itemId, newShop: newShop })
    }

    const saveDescription = async ({ newDescription }) => {
      await store.dispatch('items/updateFriendItemDescription', { itemId: props.itemId, newDescription: newDescription })
    }

    const saveLinks = async ({ newLinks }) => {
      await store.dispatch('items/updateFriendItemLinks', { itemId: props.itemId, newLinks: newLinks })
    }

    const { progressPercent: imageUploadPercentage, error: imageUploadError, executeUpload: executeImageUpload } = useApiImageUploading()

    return {
      archivedRelativeTimeAgo,

      isBusyClaiming,
      executeClaim,

      isBusyClaimingSecretly,
      executeClaimSecretly,

      isBusyUnclaiming,
      executeUnclaim,

      isSavingContribution,
      executeContribute,

      executeArchive,
      isArchiving,

      executeUnarchive,
      isUnarchiving,

      saveName,
      savePrice,
      saveRating,
      saveShop,
      saveDescription,
      saveLinks,

      imageUploadPercentage,
      imageUploadError,
      executeImageUpload,
    }
  },

  data() {
    return {
      displayMode: null,
    }
  },

  computed: {
    isEditMode() {
      return this.displayMode === 'edit'
    },

    firstImageId() {
      return this.imageIds && this.imageIds.length > 0 ? this.imageIds[0] : undefined
    },

    cardIsDarkMode() {
      return !!this.claimedBy
    },

    cardListeners() {
      if (this.displayMode) {
        return {}
      } else {
        return { click: () => this.setDisplayMode('info') }
      }
    },

    cardMenuActions() {
      const actions = []

      if (!this.isEditMode && this.isAddedByAuthUser) {
        actions.push({
          key: 'edit',
          actionFunc: () => this.setDisplayMode('edit'),
          icon: 'mdi-square-edit-outline',
          title: 'Edit',
        })
      }

      if (!this.claimedBy) {
        if (actions.length > 0) {
          actions.push({
            // divider
          })
        }

        actions.push(
          ...[
            {
              key: 'claim-secretly',
              actionFunc: () => this.executeClaimSecretly(),
              isActionBusy: this.isBusyClaimingSecretly,
              icon: 'mdi-eye-off-outline',
              title: 'Claim secretly',
            },
            {
              key: 'contribute',
              actionFunc: () => this.setDisplayMode('contribute'),
              icon: 'mdi-handshake-outline',
              title: this.displayMode === 'contribute' ? 'Hide contributions' : 'Show contributions',
            },
          ]
        )
      }

      if (this.isAddedByAuthUser) {
        if (!this.archived) {
          actions.push({
            key: 'archive',
            actionFunc: () => this.executeArchive(),
            isActionBusy: this.isArchiving,
            icon: 'mdi-trash-can-outline',
            title: 'Archive',
          })
        } else {
          actions.push({
            key: 'unarchive',
            actionFunc: () => this.executeUnarchive(),
            isActionBusy: this.isUnarchiving,
            icon: 'mdi-restore',
            title: 'Unarchive',
          })
        }
      }

      return actions
    },

    isSurpriseItem() {
      return this.addedById !== this.ownerId
    },
    isAddedByAuthUser() {
      return this.addedById && this.addedById === this.authUserId
    },
    isClaimedByAuthUser() {
      return this.claimedBy && this.claimedBy.claimerId === this.authUserId
    },
    claimedByText() {
      if (!this.claimedBy) return undefined

      if (this.isClaimedByAuthUser) {
        return this.claimedBy.isSecret ? 'Claimed by me (secretly)' : 'Claimed by me'
      }

      return this.claimedBy.isSecret ? 'Claimed secretly' : `Claimed by ${this.claimedBy.claimerName}`
    },
    authUserContributionAmount() {
      return this.contributions && this.contributions[this.authUserId] ? this.contributions[this.authUserId].amount || 0 : 0
    },
    totalContributionsByAll() {
      return this.contributions ? Object.values(this.contributions).reduce((sum, x) => sum + x.amount, 0) : 0
    },
    remainingNeededContribution() {
      return this.price - this.totalContributionsByAll
    },
    outstandingContributionAmount() {
      return this.remainingNeededContribution >= 0 ? this.remainingNeededContribution : 0
    },
  },

  watch: {
    isHighlighted: {
      handler(to) {
        if (to) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$vuetify.goTo(this.$refs.cardElement, {
                appOffset: true,
                offset: this.highlightedItemScrollOffset || 0,
              })
            })
          }, 50)
        }
      },
      immediate: true,
    },
  },

  methods: {
    setDisplayMode(section) {
      if (this.displayMode === section) {
        this.displayMode = null
      } else {
        this.displayMode = section
      }
    },

    async replaceItemMainImage({ imageId }) {
      await store.dispatch('items/replaceMainImageOfFriendSurpriseItem', { itemId: this.itemId, newImageId: imageId })
    },
    async deleteItemMainImage() {
      await store.dispatch('items/deleteMainImageOfFriendSurpriseItem', { itemId: this.itemId })
    },
  },
}
</script>

<template>
  <v-card :dark="cardIsDarkMode" v-on="cardListeners" ref="cardElement">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-text>
          <div class="d-flex align-center subtitle-1">
            <template v-if="isSurpriseItem">
              <v-tooltip v-if="isSurpriseItem" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-eye-off-outline</v-icon>
                  <!--                  <UserProfilePic v-bind="attrs" v-on="on" :user-id="addedById" size="20" />-->
                </template>
                <span v-if="isAddedByAuthUser"> Surprise item, added by me</span>
                <span v-else> Surprise item, added by {{ addedByName }}</span>
              </v-tooltip>

              <div class="mr-2"></div>
            </template>

            <ItemNameField :is-edit-mode="isEditMode" v-model="name" :save="saveName" />
          </div>

          <div :class="{ 'my-5': isEditMode }">
            <ItemCardRating :is-edit-mode="isEditMode" v-model="rating" :save="saveRating" />
          </div>

          <div class="pt-3"></div>

          <ItemPriceAndContributionSummary
            :claimed-by="claimedBy"
            :outstanding-contribution-amount="outstandingContributionAmount"
            :price="price"
            :total-contributions-by-all="totalContributionsByAll"
            :is-edit-mode="isEditMode"
          >
            <template v-slot:price>
              <ItemPriceField :is-edit-mode="isEditMode" v-model="price" :save="savePrice" />
            </template>
          </ItemPriceAndContributionSummary>

          <div class="pt-3"></div>

          <div>
            <ItemShopField :is-edit-mode="isEditMode" v-model="shop" :save="saveShop" />
          </div>
        </v-card-text>
      </div>

      <ItemImageField
        :is-edit-mode="isEditMode"
        :value="firstImageId"
        :item-id="itemId"
        :is-my-item="false"
        :image-upload-percentage="imageUploadPercentage"
        :upload-error="imageUploadError"
        :execute-upload="executeImageUpload"
        :replace-item-image="replaceItemMainImage"
        :remove-item-image="deleteItemMainImage"
      />
    </div>

    <v-expand-transition>
      <div v-if="!isEditMode && (claimedBy || archived)">
        <v-card-text>
          <v-chip-group column>
            <v-chip v-if="claimedBy" dark>
              <span>
                <v-icon>mdi-lock-outline</v-icon>
                {{ claimedByText }}
              </span>
            </v-chip>

            <v-spacer />

            <v-chip v-if="archived" dark>
              <span>
                <v-icon>mdi-trash-can-outline</v-icon>
                {{ archivedRelativeTimeAgo }}
              </span>
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div v-if="displayMode">
        <ItemInfoSection :is-edit-mode="isEditMode" :description="description" :links="links" :save-description="saveDescription" :save-links="saveLinks" />

        <v-expand-transition>
          <div v-if="displayMode === 'contribute'">
            <v-card-text class="py-0">
              <div>
                <div class="pt-5"></div>

                <ItemContributionsCard
                  :auth-user-id="authUserId"
                  :price="price"
                  :contributions="contributions"
                  :auth-user-contribution-amount="authUserContributionAmount"
                  :total-contributions-by-all="totalContributionsByAll"
                  :outstanding-contribution-amount="outstandingContributionAmount"
                  :execute-contribute="executeContribute"
                  :is-saving-contribution="isSavingContribution"
                />
              </div>

              <div class="pt-5"></div>
            </v-card-text>
          </div>
        </v-expand-transition>

        <v-card-actions @click.stop>
          <v-btn text :color="cardIsDarkMode ? 'secondary lighten-4' : 'secondary'" class="text-uppercase" @click="displayMode = null">Close </v-btn>

          <template v-if="!isEditMode">
            <v-btn
              v-if="!claimedBy"
              text
              color="primary"
              :disabled="isBusyClaiming"
              :loading="isBusyClaiming"
              class="text-uppercase"
              @click.stop="executeClaim"
            >
              Claim
            </v-btn>
            <v-btn
              v-else-if="isClaimedByAuthUser"
              text
              :color="cardIsDarkMode ? 'secondary lighten-4' : 'secondary'"
              :disabled="isBusyUnclaiming"
              :loading="isBusyUnclaiming"
              class="text-uppercase"
              @click.stop="executeUnclaim"
            >
              Unclaim
            </v-btn>
          </template>

          <v-spacer />

          <MenuWithActions :card-menu-actions="cardMenuActions" />
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>
