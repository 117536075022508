<script>
export default {
  props: {
    claimedBy: { type: Object },
    outstandingContributionAmount: { type: Number },
    price: { type: Number },
    totalContributionsByAll: { type: Number },
    isEditMode: { type: Boolean, required: true },
  },
}
</script>

<template>
  <div class="d-flex align-center">
    <slot name="price" />

    <template v-if="!isEditMode">
      <div v-if="!claimedBy && totalContributionsByAll > 0 && outstandingContributionAmount > 0" class="ml-2">
        <v-chip label color="warning" small> R {{ outstandingContributionAmount }} needed</v-chip>
      </div>

      <div v-if="!claimedBy && totalContributionsByAll > 0 && outstandingContributionAmount <= 0" class="ml-2">
        <v-chip label color="success" small> Fully contributed</v-chip>
      </div>
    </template>
  </div>
</template>
