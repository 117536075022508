<script>
import UserProfilePic from '@/features/users/components/UserProfilePic'

export default {
  components: {
    UserProfilePic,
  },

  props: {
    authUserId: { type: String, required: true },
    price: { type: Number },
    contributions: { type: Object },
    authUserContributionAmount: { type: Number },
    totalContributionsByAll: { type: Number },
    outstandingContributionAmount: { type: Number },
    executeContribute: { type: Function },
    isSavingContribution: { type: Boolean },
  },

  data() {
    return {
      amountToContribute: 0,
    }
  },

  computed: {
    contributionRows() {
      return Object.keys(this.contributions)
        .map((userId) => ({ contributorUserId: userId, contribution: this.contributions[userId] }))
        .sort((a, b) => (a.contribution.contributorName > b.contribution.contributorName ? 1 : -1))
    },

    contributionsTableRows() {
      return [
        ...this.contributionRows.map((r) => ({
          left: this.authUserId === r.contributorUserId ? 'You' : r.contribution.contributorName,
          picUserId: r.contributorUserId,
          right: r.contribution.amount,
          rowCssClass: 'grey--text',
        })),

        { left: 'Total', right: this.totalContributionsByAll, rowCssClass: 'primary--text font-weight-bold' },

        { left: 'Price', right: this.price, rowCssClass: 'font-weight-bold' },

        {
          left: 'Outstanding',
          right: this.outstandingContributionAmount,
          rowCssClass: this.outstandingContributionAmount > 0 ? 'error--text font-weight-bold' : 'success--text font-weight-bold',
        },
      ]
    },
  },

  watch: {
    authUserContributionAmount: {
      handler(to) {
        if (to) {
          this.amountToContribute = Number(to)
        }
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <v-card class="pa-3" tile>
    <v-row align="center">
      <v-col cols="5">
        <v-text-field label="Your amount..." v-model.number="amountToContribute" type="number" step="1" outlined clearable hide-details />

        <div class="pt-3"></div>

        <v-btn outlined color="primary" block :disabled="isSavingContribution" :loading="isSavingContribution" @click="executeContribute(amountToContribute)">
          <v-icon left>mdi-handshake-outline</v-icon>
          Contribute
        </v-btn>
      </v-col>
      <v-col cols="7">
        <div class="text-right">
          <table class="ml-auto">
            <tr v-for="row in contributionsTableRows" :key="row.left" :class="row.rowCssClass">
              <td class="text-right">
                <div class="d-flex align-center justify-end">
                  <div>{{ row.left }}</div>
                  <UserProfilePic v-if="row.picUserId" :user-id="row.picUserId" size="15" class="ml-2" />
                </div>
              </td>
              <td class="text-left pl-3">{{ row.right }}</td>
            </tr>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
