<script>
import config from '@/config'

export default {
  props: {
    userId: { type: String, required: true },
  },

  computed: {
    imageSrc() {
      return `${config.ApiBaseUrl}/UserProfilePics/${this.userId}`
    },
  },
}
</script>

<template>
  <v-avatar v-bind="$attrs" v-on="$listeners">
    <v-img :src="imageSrc">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate size="15" width="1" color="green"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-avatar>
</template>
